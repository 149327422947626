import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/website.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function InstagramEmbed() {
  useEffect(() => {
    // Dynamically load the Instagram embed script after the component mounts
    const script = document.createElement("script");
    script.src = "//www.instagram.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script if the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
   <blockquote
    className="instagram-media"
    data-instgrm-permalink="https://www.instagram.com/gabriel_posada_/"
    style={{
      margin: "1px",
      minWidth: "326px",
      padding: 0,
      width: "100%",
      width: "calc(100% - 2px)",
    }}
  >
    <div style={{ padding: "16px" }}>
      <a
        href="https://www.instagram.com/gabriel_posada_/"
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >

      </a>
    </div>
  </blockquote>
  );
}

function Home3() {
  return (
    <section>
      <Container fluid >
        <Container>
               <Row>
          <Col md={12} className="home-about-social">
            <h1>
              CONTACT <span className="purple">ME</span>
            </h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Dawnbrightsu"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/gabriel_posada_"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/gabriel-posada-57b28332b"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>

            <p>
              Feel free to reach out to me on any of my{" "}
              <span className="purple"> social media </span>
            </p>
          </Col>

        <Row style={{ padding: "40px" }}>
          <Col md={6}>
            {/* GitHub Profile Embed */}
            <img
              src="https://github-readme-stats.vercel.app/api/top-langs/?username=Dawnbrightsu&layout=compact&theme=radical"
              alt="Top Languages"
              style={{ width: "100%"}}
            />
          </Col>
          <Col md={6}>
            <InstagramEmbed />
          </Col>
        </Row>

          </Row>
        </Container>
      </Container>

    </section>
  );
}

export default Home3;
