import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/website.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                </h1>

              <h1 className="heading-name">
                I'M<br/>
                <strong className="main-name"> Dawnbrightsu</strong> / <br/> <strong className="main-name" style={{fontSize: "2.1em"}} > Gabriel Posada</strong> 
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
