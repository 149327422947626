import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>I am from <span className="purple"> New Westminster, BC, Canada</span>. I am a Store Manager at Canada Computers & Electronics. With a passion for technology, leadership, and problem-solving, I focus on ensuring our store runs efficiently while delivering top-notch service to our customers.            
           <br /><br />
            I studied Computer Science from Carleton University and have over 10 years of programming experience, both as a hobby and in professional environments. This technical background gives me a unique perspective on the products we offer and helps me engage effectively with both customers and staff.            <br />
            <br />
            With over 8 years of experience in customer service and retail management, I have developed strong skills in team leadership, customer satisfaction, and operational excellence. At Canada Computers & Electronics, I oversee day-to-day store activities, ensure inventory accuracy, and coach staff to achieve our sales goals.
<br /><br />
In addition to being fluent in both Spanish and English, I enjoy programming, rock climbing, skiing, and hanging out with friends. These activities keep me balanced and energized, and I believe that maintaining this balance helps me lead more effectively in the workplace.          
</p>
         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
